<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'firefighting-equipment-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'firefightingEquipmentInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'firefightingEquipmentInfo', icon: 'info', label: '기본정보', component: () => import(`${'./firefightingEquipmentInfo.vue'}`) },  // 소화설비 설치계획
        { name: 'firefightingEquipmentPid', icon: 'tab', label: '도면', component: () => import(`${'./firefightingEquipmentPid.vue'}`) }, // 소화설비 설치도
        { name: 'firefightingEquipmentDoc', icon: 'assignment', label: '문서', component: () => import(`${'./firefightingEquipmentDoc.vue'}`) } // 소화설비 설치 계획 문서
      ]
    },

  }
};
</script>